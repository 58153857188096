/* eslint-disable no-unused-vars */
import React, { useContext } from "react";
//import icons
import { FaFacebookF } from "react-icons/fa";
import { FaXTwitter } from "react-icons/fa6";
import { FaPinterestP } from "react-icons/fa";
import { FaInstagram } from "react-icons/fa";
import { FaYoutube } from "react-icons/fa6";
//cursor context
import { CursorContext } from "../context/CursorContext";

const Socials = () => {
  const { mouseEnterHandler, mouseLeaveHandler } = useContext(CursorContext);
  return (
    <div
      onMouseEnter={mouseEnterHandler}
      onMouseLeave={mouseLeaveHandler}
      className="hidden lg:flex ml-16"
    >
      <ul className="flex gap-x-4">
        <li className="text-[#696c6d] hover:text-primary transition">
          <a href="https://www.facebook.com" target="_blank" rel="noreferrer">
            <FaFacebookF />
          </a>
        </li>
        <li className="text-[#696c6d] hover:text-primary transition">
          <a href="https://www.twitter.com" target="_blank" rel="noreferrer">
            <FaXTwitter />
          </a>
        </li>
        <li className="text-[#696c6d] hover:text-primary transition">
          <a href="https://www.pinterest.com" target="_blank" rel="noreferrer">
            <FaPinterestP />
          </a>
        </li>
        <li className="text-[#696c6d] hover:text-primary transition">
          <a href="https://www.instagram.com" target="_blank" rel="noreferrer">
            <FaInstagram />
          </a>
        </li>
        <li className="text-[#696c6d] hover:text-primary transition">
          <a href="https://www.youtube.com" target="_blank" rel="noreferrer">
            <FaYoutube />
          </a>
        </li>
      </ul>
    </div>
  );
};

export default Socials;
